import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierLove = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierLoveBracelet {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Love Bracelet for Sale'}
      canonical={'/jewelry/designer-jewelry/cartier-jewelry/cartier-bracelets/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Love Bracelet with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/jewelry/designer-jewelry/cartier-jewelry/filter/cat/cartier-bracelets/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-love.png"
                alt="Pre-Owned Certified Used Cartier Love Header"
                aria-label="Used Cartier Love Bracelet Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER LOVE BRACELETS</h1>
            <h2>Hottest Collection of Pre-Owned Cartier Love Bracelets</h2>
            <h3>BUY, SELL, TRADE, CONSIGN, REPAIR USED CARTIER JEWELRY &amp; WATCHES</h3>
            <p>
              Cartier Bracelets in general and Cartier Love Bracelets in particular have an
              extraordinary and unforgettable look. Gray &amp; Sons Jewelers is presenting in stock
              inventory of Pre-Owned Cartier Love Bracelets, a remarkable most wanted collection of
              blissful jewelry. The stunning Cartier Love Bracelets pre owned are designed for men
              and women in different models including small model (slimmer version) and measured in
              centimeters for size. They may be offered as sets with matching screwdriver to open
              and close the bracelet.
              <br />
              <br />
              Cartier jewelry lovers always cherish shopping for Pre-owned Cartier Love Bracelets at
              Gray and Sons. Now, it is feasible to choose your favorite Cartier jewelry and watches
              from the comfort of your home. We offer estate, used, pre-owned, heirloom, antique
              Cartier bracelets and bangles. Our customers say we are their favorite and #1 luxury
              Cartier jewelry seller.
              <br />
              <br />
              Shop our in stock Cartier Love Bracelets inventory online at{' '}
              <a href="/jewelry/designer-jewelry/cartier-jewelry">www.grayandsons.com </a>
              Make a deal with our jewelry specialist, a real person, Vika,{' '}
              <a href="/chat-with-viktoria">HERE </a> on a live chat. Or you can visit Gray &amp;
              Sons’ in our luxury watch and jewelry showroom located in Surfside, Miami, Florida six
              days a week. Our jewelry specialists will always welcome you here. Feel free to ask
              questions about our procedure or about the pre-owned Cartier love bracelet.
              <br />
              <br />
              <b>Obsessing Over Cartier Preowned Love Bracelets </b>
              <br />
              <br />
              We are highly obsessed with our gem designs of Cartier preowned love bracelets and
              used Cartier love bangle collection. Because it does not only get thousands of
              admirable compliments but also expresses the real sense of enticing style. The
              luxurious and lustrous Cartier collection consists of yellow gold, rose gold, white
              gold Cartier love bracelets, Cartier bracelet can be enhanced with diamonds, called
              diamond studded. These bracelets are for men and for women. They are referenced to by
              some as the modern love handcuff.
              <br />
              <br />
              Like the great-fitting jeans and flawless red lipstick, these bracelets instantly add
              whimsical charm to an entire look. Whether you wear a classy or light dress, used
              vintage Cartier bracelets and bangles will rock both. You can absolutely carry it at
              night parties, long drives, dinner or brunch with friends. The timeless and stylish
              design will lift any of your amazing looks.
              <br />
              <br />
              <b>Cartier Love Bracelets Epitome of Pure Love </b>
              <br />
              <br />
              In the world of high end statement bracelets, fashion-forward men and women have a
              great appreciation towards preowned Cartier jewelry. Especially Cartier gold love
              bracelets and Cartier love bracelet small model. Such admirable bracelets and bangles
              are world-famous for their sophisticated and exceptional designs. If you are hunting
              for a precious gift for your special one, you will succeed by buying a preowned
              Cartier love bracelet with diamonds. A symbol of locked love forever. Gray and Sons
              sales team is excellent help if you need assistance choosing the right Cartier jewelry
              as a gift for her and for him. Our Cartier used bracelets promise a long-life of
              beauty and glam to everyone’s dress either simple or fascinating.
              <br />
              <br />
              <b>Trade, Sell or Consign Cartier Used Bracelets </b>
              <br />
              <br />
              Gray &amp; Sons is not only the best place to buy used Cartier bracelets but also the
              number one jewelry store to sell Cartier pre-owned jewelry. Yes! You can sell Cartier
              jewelry second hand at and get paid the best prices. Visit{' '}
              <a href="https://sellusyourjewelry.com/"> www.sellusyourjewelry.com </a> to get
              highest price in cash for your Cartier love bracelet. Gray and Sons Jewelers is
              proudly your “unauthorized”, certified pre-owned, restored luxury watch and estate
              jewelry discounter.
            </p>
            <div className="w-full flex justify-start">
              <a href="/jewelry/designer-jewelry/cartier-jewelry/filter/cat/cartier-bracelets/">
                <button>SHOP CARTIER JEWELRY</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER LOVE BRACELETS</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierLove
